var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resource-modal-wrap"},[_c('a-modal',{attrs:{"title":_vm.modalTitle,"maskClosable":false,"footer":null,"width":"1000px","destroyOnClose":""},on:{"cancel":_vm.handleModalCancel},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"search"},[_c('a-form-model',{ref:"searchFormRef",attrs:{"model":_vm.searchForm}},[_c('a-form-model-item',{attrs:{"label":"已选资源"}},[_c('a-spin',{attrs:{"spinning":_vm.spinning}},_vm._l((_vm.displaySelectedRows),function(item,index){return _c('a-row',{key:index},[_c('a-col',{attrs:{"span":24}},[_c('a-tag',{attrs:{"color":"#108ee9","closable":""},on:{"close":function($event){return _vm.removeSelected(item, $event)}}},[_vm._v(" "+_vm._s(item.resName)+" ")]),_vm._l((item.children),function(record,recordIndex){return _c('a-tag',{key:recordIndex,attrs:{"color":"blue","closable":""},on:{"close":function($event){return _vm.removeSelected(record, $event)}}},[_vm._v(" "+_vm._s(record.resName)+" ")])})],2)],1)}),1)],1),_c('a-form-model-item',{attrs:{"prop":"name"}},[_c('a-input-search',{attrs:{"allow-clear":"","placeholder":"请输入资源名称搜索"},on:{"search":_vm.handleSearchSubmit},model:{value:(_vm.searchForm.name),callback:function ($$v) {_vm.$set(_vm.searchForm, "name", $$v)},expression:"searchForm.name"}})],1)],1)],1)]),_c('a-table',{attrs:{"rowKey":"sequenceNbr","columns":_vm.resourceColumns,"data-source":_vm.resourceData,"scroll":{ x: 'max-content' },"pagination":_vm.pagination,"loading":_vm.tableLoading,"expandIconColumnIndex":1,"expandedRowKeys":_vm.expandedRowKeys,"row-selection":{
        type: 'checkbox',
        columnTitle: ' ', // 去掉全选
        hideDefaultSelections: true, // 去掉全选
        selectedRowKeys: _vm.selectedRowKeys,
        onSelect: _vm.handleOnSelect,
        getCheckboxProps: function (record) {
          return {
            props: {
              disabled: record.level === 1 && record.children === null,
            },
          };
        },
      }},on:{"update:expandedRowKeys":function($event){_vm.expandedRowKeys=$event},"update:expanded-row-keys":function($event){_vm.expandedRowKeys=$event},"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"expandIcon",fn:function(ref){
      var expanded = ref.expanded;
      var record = ref.record;
return [(record.children && record.children.length)?_c('span',[(!expanded)?_c('a-icon',{staticClass:"caret-icon",attrs:{"type":"caret-right"},on:{"click":function($event){return _vm.openExpanded(record.sequenceNbr)}}}):_c('a-icon',{staticClass:"caret-icon",attrs:{"type":"caret-down"},on:{"click":function($event){return _vm.closeExpanded(record.sequenceNbr)}}})],1):_c('span',{staticStyle:{"padding-left":"24px"}})]}},{key:"resDescription",fn:function(text){return [_c('div',{staticClass:"toolTipStyle"},[_c('a-tooltip',{attrs:{"placement":"bottomLeft"}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(text)+" ")]),_vm._v(" "+_vm._s(text)+" ")],2)],1)]}}])}),_c('div',{staticClass:"modalFooterTool"},[_c('a-button',{on:{"click":_vm.handleFormCancel}},[_vm._v("取消")]),_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary"},on:{"click":_vm.handleFormSave}},[_vm._v(" 确定 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }