




























































































































import Vue from 'vue';
import * as api from '@/api/appManage';
import { message, Modal } from 'ant-design-vue';
export default Vue.extend({
  name: 'selectResource',
  props: [],
  data() {
    return {
      clientId: '',
      showModal: false,
      modalTitle: '添加资源',
      spinning: false,
      tableLoading: false,
      searchForm: {
        name: '', // 优惠券名称
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      resourceColumns: [
        {
          title: '资源名称',
          dataIndex: 'resName',
          align: 'left',
        },
        {
          title: '资源来源',
          dataIndex: 'serviceName',
          align: 'left',
        },
        {
          title: '资源版本',
          dataIndex: 'resVersion',
          align: 'left',
        },
        {
          title: '资源描述',
          dataIndex: 'resDescription',
          align: 'left',
          scopedSlots: { customRender: 'resDescription' },
        },
      ],
      resourceData: [] as any,
      selectedRowKeys: [] as string[], //选中的数据key
      selectedRows: [] as any, //选中的数据
      displaySelectedRows: [] as any, //选中的数据父子结构
      expandedRowKeys: [] as string[],
    };
  },
  watch: {
    selectedRows(val) {
      if (val) {
        const parentList = this.selectedRows.filter((item) => item.level === 1);
        this.displaySelectedRows = parentList.map((item) => {
          return {
            ...item,
            children: this.selectedRows.filter(
              (record) => record.parentSequence === item.sequenceNbr
            ),
          };
        });
        // console.log('displaySelectedRows:', this.displaySelectedRows);
      }
    },
  },
  methods: {
    show(id) {
      this.showModal = true;
      this.clientId = id;
      this.handleSearchSubmit();
      this.getModalResourceListSelected();
    },
    openExpanded(id) {
      if (this.expandedRowKeys.includes(id)) return '';
      this.expandedRowKeys.push(id);
    },
    closeExpanded(id) {
      const index = this.expandedRowKeys.indexOf(id);
      if (index >= 0) {
        this.expandedRowKeys.splice(index, 1);
      }
    },
    removeSelected(record, e) {
      e.preventDefault();
      Modal.confirm({
        title: '确定要取消该资源吗？',
        content: '取消之后该产品不再有此资源。',
        onOk: () => {
          this.selectedRows = this.selectedRows.filter(
            (item) => item.sequenceNbr !== record.sequenceNbr
          );
          const childRows = this.selectedRows.filter(
            (item) => item.parentSequence === record.sequenceNbr
          );
          if (childRows.length > 0) {
            this.unSelectDataWithChild(record, this.selectedRows);
          } else {
            this.unSelectDataWithoutChild(record, this.selectedRows);
          }
        },
      });
    },
    // 获取已添加的资源列表数据
    getModalResourceListSelected() {
      this.spinning = true;
      api
        .getModalResourceListSelected(this.clientId)
        .then((res) => {
          if (res.status === 200) {
            const selected = res.result;
            this.selectedRowKeys = selected.map((item) => item.resId);
            //更改已选数据中sequenceNbr为原表格数据的sequenceNbr
            this.selectedRows = selected.map((item) => {
              return {
                ...item,
                sequenceNbr: item.resId,
                parentSequence: item.resParentId,
              };
            });
          }
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    // 获取资源列表数据
    getModalResourceList() {
      this.tableLoading = true;
      api
        .getModalResourceList(
          this.pagination.current,
          this.pagination.pageSize,
          1,
          this.searchForm.name,
          this.clientId,
          'A'
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.resourceData = res.result.list;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 组合查询表单提交
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getModalResourceList();
    },
    // 组合查询表单重置
    handleSearchReset() {
      (this.$refs.searchFormRef as HTMLFormElement).resetFields();
      this.pagination.current = 1;
      this.getModalResourceList();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getModalResourceList();
    },
    handleOnSelect(record, selected, selectedRows, nativeEvent) {
      // console.log('record:', record);
      // console.log('selected:', selected);
      // console.log('selectedRows:', selectedRows);
      // console.log('nativeEvent:', nativeEvent);
      if (selected) {
        this.selectedRows = [...this.selectedRows, record];
        if (record.children && record.children.length) {
          this.selectDataWithChild(record, this.selectedRows);
        } else {
          this.selectDataWithoutChild(record, this.selectedRows);
        }
      } else {
        Modal.confirm({
          title: '确定要取消该资源吗？',
          content: '取消之后该产品不再有此资源。',
          onOk: () => {
            this.selectedRows = this.selectedRows.filter(
              (item) => item.sequenceNbr !== record.sequenceNbr
            );
            if (record.children && record.children.length) {
              this.unSelectDataWithChild(record, this.selectedRows);
            } else {
              this.unSelectDataWithoutChild(record, this.selectedRows);
            }
          },
        });
      }
    },
    //选中没有子节点的当前节点数据
    selectDataWithoutChild(record, selectedRows) {
      let selectedRowKeys = selectedRows.map((item) => item.sequenceNbr);
      // level>1 为叶子节点，level=1 为空的目录
      if (record.level > 1) {
        if (selectedRowKeys.includes(record.parentSequence)) {
          this.selectedRowKeys = [...selectedRowKeys];
          this.selectedRows = [...selectedRows];
        } else {
          let parent = this.resourceData.filter(
            (item) => item.sequenceNbr === record.parentSequence
          );
          this.selectedRowKeys = [...selectedRowKeys, record.parentSequence];
          this.selectedRows = [...selectedRows, ...parent];
        }
      } else {
        this.selectedRowKeys = [...selectedRowKeys];
        this.selectedRows = [...selectedRows];
      }
    },
    //取消没有子节点的当前节点数据
    unSelectDataWithoutChild(record, selectedRows) {
      let selectedRowKeys = selectedRows.map((item) => item.sequenceNbr);
      // level>1 为叶子节点，level=1 为空的目录
      if (record.level > 1) {
        //获取已选中集合中当前节点的兄弟节点
        const selectedChildRows = selectedRows.filter(
          (item) => item.parentSequence === record.parentSequence
        );
        if (selectedChildRows.length > 0) {
          //存在被选中的其他兄弟节点
          this.selectedRowKeys = [...selectedRowKeys];
          this.selectedRows = [...selectedRows];
        } else {
          //不存在被选中的其他兄弟节点
          this.selectedRowKeys = selectedRowKeys.filter(
            (item) => item !== record.parentSequence
          );
          this.selectedRows = selectedRows.filter(
            (item) => item.sequenceNbr !== record.parentSequence
          );
        }
      } else {
        this.selectedRowKeys = [...selectedRowKeys];
        this.selectedRows = [...selectedRows];
      }
    },
    //选中有子节点的当前节点数据
    selectDataWithChild(record, selectedRows) {
      let selectedRowKeys = selectedRows.map((item) => item.sequenceNbr);
      // 获取父节点下未选择的子节点集合
      const childRows = record.children.filter(
        (item) => !this.selectedRowKeys.includes(item.sequenceNbr)
      );
      // 获取父节点下未选择的子节点key集合
      const childRowKeys = childRows.map((item) => item.sequenceNbr);
      // 全选父节点下所有子集
      this.selectedRowKeys = [...selectedRowKeys, ...childRowKeys];
      this.selectedRows = [...selectedRows, ...childRows];
    },
    //取消有子节点的当前节点数据
    unSelectDataWithChild(record, selectedRows) {
      this.selectedRows = selectedRows.filter(
        (item) => item.parentSequence !== record.sequenceNbr
      );
      this.selectedRowKeys = this.selectedRows.map((item) => item.sequenceNbr);
    },
    handleFormCancel() {
      this.showModal = false;
      this.clearSelectdRows();
    },
    handleModalCancel() {
      this.clearSelectdRows();
    },
    handleFormSave() {
      if (this.selectedRows.length) {
        //只需要给后台传递不包含父级的数据
        const currentData = this.selectedRows.filter((item) => item.level > 1);
        this.$emit('handleSaveResourceDatas', currentData);
        this.showModal = false;
        this.clearSelectdRows();
      } else {
        message.error('请选择资源！');
      }
    },
    //清空已选择
    clearSelectdRows() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
  },
});
