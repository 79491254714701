








































































































































































































































































































































import Vue from 'vue';
import { message, Modal } from 'ant-design-vue';
import editableCell from './editableCell.vue';
import transferModal from './transferModal.vue';
import resourceModal from './resourceModal.vue';
import * as api from '@/api/appManage';
import moment from 'moment';
export default Vue.extend({
  name: 'appManageDetails',
  components: {
    editableCell,
    transferModal,
    resourceModal,
  },
  data() {
    return {
      tableLoading: false,
      resourceTableLoading: false,
      searchForm: {
        queryStatus: '1', //产品租户状态查询, 1:正常，0:停用，''/null:全部
      },
      searchResourceForm: {
        queryStatus: '', //产品资源状态查询, 1:正常，0:停用，''/null:全部
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      paginationResource: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      appInfoLoading: false,
      appInfo: {
        applicationLogo: '',
        applicationName: '',
        clientId: '',
        plaintextSecret: '',
        appAuthCallbackUrl: '',
        userCallbackUrl: '',
        userName: '',
        creationTime: '',
        description: '',
      },
      appManageRoleColumns: [
        {
          title: '姓名',
          dataIndex: 'realName',
        },
        {
          title: '账号',
          dataIndex: 'userCode',
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      appManageRoleData: [],
      appTenantColumns: [
        {
          title: '租户名称',
          dataIndex: 'enterpriseName',
        },
        // {
        //   title: '租户类型',
        //   dataIndex: 'tenantType',
        // },
        {
          title: '账号',
          dataIndex: 'nickName',
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
        },
        {
          title: '邮箱',
          dataIndex: 'mailbox',
        },
        {
          title: '最后登录时间',
          dataIndex: 'lastLoginTime',
          scopedSlots: { customRender: 'lastLoginTime' },
        },
        {
          title: '状态',
          dataIndex: 'agenciesStatus',
          scopedSlots: { customRender: 'agenciesStatus' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      appTenantData: [],
      resourceColumns: [
        {
          title: '序号',
          align: 'left',
          dataIndex: 'number',
          width: '80px',
          customRender: (text, record, index) =>
            record.level == 1 ? index + 1 : '',
        },
        {
          title: '资源名称',
          dataIndex: 'resName',
        },
        {
          title: '资源别名',
          dataIndex: 'resAlias',
          scopedSlots: { customRender: 'resourceAlias' },
        },
        {
          title: '资源来源',
          dataIndex: 'sourceName',
        },
        {
          title: '资源版本',
          dataIndex: 'resVersion',
        },
        {
          title: '资源描述',
          dataIndex: 'resDescription',
          scopedSlots: { customRender: 'resDescription' },
        },
        {
          title: '创建人',
          dataIndex: 'creator',
        },
        {
          title: '创建时间',
          dataIndex: 'recDate',
          scopedSlots: { customRender: 'creationTime' },
        },
        {
          title: '状态',
          dataIndex: 'bizStatus',
          scopedSlots: { customRender: 'bizStatus' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      resourceData: [] as any,
      expandedRowKeys: [] as string[],
      statisticData: {
        total: 0, //总数
        effectiveNum: 0, //有效的
        overdueNum: 0, //过期的
        invalidNum: 0, //无效的
      },
      transferTargetKeys: [],
      transferData: [],
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.getAppManageDetail(this.$route.query.id + '');
      this.getAgencyStatistics(this.$route.query.id + '');
      this.getTargetTransferData(this.$route.query.id + '', 'SUPER_ADMIN');
    }
    this.getAgenciesTenant();
    // 生成环境暂不开放资源池相关功能
    // this.getResourceList();
    this.getAllTransferData('SUPER_ADMIN');
  },
  methods: {
    moment,
    //产品状态修改事件
    handleAppStatus(checked) {
      if (checked) {
        this.changeAppStatus(this.$route.query.id + '', '1');
      } else {
        Modal.confirm({
          title: '确定将该产品服务置为停用状态吗？',
          onOk: () => {
            this.changeAppStatus(this.$route.query.id + '', '2');
          },
        });
      }
    },
    //产品状态修改接口
    changeAppStatus(clientId: string, bizStatus: string) {
      api.changeAppStatus(clientId, bizStatus).then((res) => {
        if (res.status === 200) {
          // console.log('changeAppStatus:', res);
          //重新加载产品基本信息
          this.getAppManageDetail(this.$route.query.id + '');
        }
      });
    },
    //资源状态修改事件
    handleResourceStatus(checked, record) {
      if (checked) {
        api.enableResourceStatus(record.sequenceNbr).then((res) => {
          if (res.status === 200) {
            // console.log('enableResourceStatus:', res);
            //刷新资源列表
            this.getResourceList();
          } else {
            message.error(res.message);
          }
        });
      } else {
        Modal.confirm({
          title: '确定要关闭该资源吗？',
          content: '关闭之后该产品不再有此资源权益。',
          onOk: () => {
            api.disableResourceStatus(record.sequenceNbr).then((res) => {
              if (res.status === 200) {
                // console.log('disableResourceStatus:', res);
                //刷新资源列表
                this.getResourceList();
              } else {
                message.error(res.message);
              }
            });
          },
        });
      }
    },
    //资源别名修改事件
    onCellChange(sequenceNbr, value) {
      api.setResourceAlias(sequenceNbr, value).then((res) => {
        if (res.status === 200) {
          //刷新资源列表
          this.getResourceList();
        } else {
          message.error(res.message);
        }
      });
    },
    //资源列表展开
    openExpanded(id) {
      if (this.expandedRowKeys.includes(id)) return '';
      this.expandedRowKeys.push(id);
    },
    //资源列表折叠
    closeExpanded(id) {
      const index = this.expandedRowKeys.indexOf(id);
      if (index >= 0) {
        this.expandedRowKeys.splice(index, 1);
      }
    },
    //租户状态修改事件
    handleAgenciesStatus(checked, record) {
      if (checked) {
        this.changeAgenciesStatus(this.$route.query.id, record.agencyCode, 1);
      } else {
        Modal.confirm({
          title: '确定限制该租户无法访问产品吗？',
          onOk: () => {
            this.changeAgenciesStatus(
              this.$route.query.id,
              record.agencyCode,
              0
            );
          },
        });
      }
    },
    //租户状态修改接口
    changeAgenciesStatus(productCode, agencyCode, type) {
      api.changeAgenciesStatus(productCode, agencyCode, type).then((res) => {
        if (res.status === 200) {
          console.log('changeAgenciesStatus:', res);
          //重新加载租户表格信息
          this.getAgenciesTenant();
          //重新加载统计信息
          this.getAgencyStatistics(productCode);
        }
      });
    },
    //获取统计信息接口
    getAgencyStatistics(id: string) {
      api.getAgencyStatistics(id).then((res) => {
        if (res.status === 200) {
          this.statisticData = res.result;
        } else {
          message.error(res.message);
        }
      });
    },
    //获取产品详情接口
    getAppManageDetail(id: string) {
      this.appInfoLoading = true;
      api
        .getAppManageDetail(id)
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.result);
            this.appInfo = res.result;
          }
        })
        .finally(() => {
          this.appInfoLoading = false;
        });
    },
    //租户列表组合查询
    handleTenantSearch() {
      this.pagination.current = 1;
      this.getAgenciesTenant();
    },
    //资源列表组合查询
    handleResourceSearch() {
      this.paginationResource.current = 1;
      this.getResourceList();
    },
    //租户列表翻页控制
    handleTenantChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getAgenciesTenant();
    },
    //资源列表翻页控制
    handleResourceChange(pagination) {
      const { current, pageSize } = pagination;
      this.paginationResource.current = current;
      this.paginationResource.pageSize = pageSize;
      this.getResourceList();
    },
    //获取资源列表数据
    getResourceList() {
      this.resourceTableLoading = true;
      api
        .getResourceList(
          this.paginationResource.current,
          this.paginationResource.pageSize,
          this.$route.query.id + '',
          this.searchResourceForm.queryStatus
        )
        .then((res) => {
          if (res.status === 200) {
            this.paginationResource.total = res.result.total;
            this.resourceData = res.result.list;
          }
        })
        .finally(() => {
          this.resourceTableLoading = false;
        });
    },
    //获取租户列表数据
    getAgenciesTenant() {
      this.tableLoading = true;
      api
        .getAgenciesTenant(
          this.pagination.current,
          this.pagination.pageSize,
          this.$route.query.id + '',
          this.searchForm.queryStatus
        )
        .then((res) => {
          if (res.status === 200) {
            // console.log('getAgenciesTenant');
            // console.log(res.result);
            this.pagination.total = res.result.total;
            this.appTenantData = res.result.list;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    //查询穿梭框中的目标数据
    getTargetTransferData(appCode: string, agencyCode: string) {
      api.getTargetTransferData(appCode, agencyCode).then((res) => {
        if (res.status === 200) {
          // console.log('getAppManageRoleList');
          // console.log(res.result);
          this.appManageRoleData = res.result;
          //赋值穿梭框中已选中数据
          this.transferTargetKeys = res.result.map((item) => item.openId);
          // console.log('transferTargetKeys:', this.transferTargetKeys);
        } else {
          message.error(res.message);
        }
      });
    },
    //查询穿梭框中的所有数据
    getAllTransferData(agencyCode: string) {
      api.getAllTransferData(agencyCode).then((res) => {
        if (res.status === 200) {
          this.transferData = res.result.map((item) => {
            return {
              ...item,
              key: item.openId,
              disabled: false,
              description: '',
            };
          });
        } else {
          message.error(res.message);
        }
      });
    },
    //删除产品管理员
    onDelete(key) {
      const params = {
        appCode: this.$route.query.id,
        roleCode: 'APP_ADMIN',
        agencyCode: 'SUPER_ADMIN',
        deleteIds: [key],
        addIds: [],
      };
      // console.log('onDelete-params:', params);
      api.saveTransferData(params).then((res) => {
        if (res.status === 200) {
          if (res.result) {
            message.success('操作成功');
            // 删除成功，刷新数据
            this.getTargetTransferData(
              this.$route.query.id + '',
              'SUPER_ADMIN'
            );
          }
        } else {
          message.error('操作失败');
        }
      });
    },
    //打开产品管理员选择弹框
    showModal() {
      (this.$refs.transferModal as HTMLFormElement).showModal = true;
    },
    //打开产品资源选择弹框
    showResourceModal() {
      (this.$refs.resourceModal as HTMLFormElement).show(this.$route.query.id);
    },
    //保存产品管理员数据
    handleSaveDatas(currentData) {
      const params = {
        appCode: this.$route.query.id,
        roleCode: 'APP_ADMIN',
        agencyCode: 'SUPER_ADMIN',
        deleteIds: currentData.deleteIds,
        addIds: currentData.addIds,
      };
      // console.log('handleSaveDatas-params:', params);
      api.saveTransferData(params).then((res) => {
        if (res.status === 200) {
          if (res.result) {
            message.success('操作成功');
            (this.$refs.transferModal as HTMLFormElement).showModal = false;
            // 保存成功，刷新数据
            this.getTargetTransferData(
              this.$route.query.id + '',
              'SUPER_ADMIN'
            );
          }
        } else {
          message.error('操作失败');
        }
      });
    },
    //保存产品资源数据
    handleSaveResourceDatas(currentData) {
      const resourcesList = currentData.map((item) => {
        return {
          parentSequence: item.parentSequence,
          sequenceNbr: item.sequenceNbr,
          bizStatus: item.bizStatus,
          resAlias: item.resAlias,
        };
      });
      const params = {
        productCode: this.$route.query.id,
        resourcesList: resourcesList,
      };
      // console.log('handleSaveResourceDatas:', params);
      api.saveResourceData(params).then((res) => {
        if (res.status === 200) {
          if (res.result) {
            message.success('操作成功');
            // 保存成功，刷新数据
            this.getResourceList();
          }
        } else {
          message.error('操作失败');
        }
      });
    },
    //复制到剪切板操作
    copyToClipboard(appKey: string, appSecret: string) {
      const copyContent = `AppKey：${appKey}  AppSecret：${appSecret}`;
      // navigator clipboard 需要https等安全上下文
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(copyContent).then(
          () => {
            this.$message.success('复制成功');
          },
          () => {
            this.$message.success('您的浏览器暂不支持此功能');
          }
        );
      } else {
        // 创建text area
        let textArea = document.createElement('textarea') as any;
        textArea.value = copyContent; // 使text area不在viewport，同时设置不可见
        textArea.style.position = 'absolute';
        textArea.style.opacity = 0;
        textArea.style.left = '-999999px';
        textArea.style.top = '-999999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // 执行复制命令并移除文本框
          document.execCommand('copy') ? res : rej;
          textArea.remove();
          this.$message.success('复制成功!');
        });
      }
    },
  },
});
